import { graphql, useStaticQuery } from 'gatsby';
import React, { FunctionComponent, MouseEvent } from 'react';
import CauseIcon from '~/images/cause-login.svg';
import PartnerIcon from '~/images/partner-login.svg';

interface LoginModalProps {
  close: (event: MouseEvent<HTMLDivElement>) => void;
}

const LoginModal: FunctionComponent<LoginModalProps> = ({ close }) => {
  const { wp } = useStaticQuery(graphql`
    query {
      wp {
        options {
          globalOptions {
            loginPage {
              image {
                ...Image
              }
            }
          }
        }
      }
    }
  `);

  const { loginPage } = wp.options.globalOptions;

  const items = [
    {
      icon: <PartnerIcon />,
      heading: "I'm a partner",
      content: 'Log into Partners Dashboard',
      url: 'https://partners.poweredbypercent.com/login',
    },
    {
      icon: <CauseIcon />,
      heading: "I'm a non-profit",
      content: 'Log into Causes Dashboard',
      url: 'https://causes.poweredbypercent.com/login',
    },
  ];

  return (
    <section className="fixed top-0 left-0 w-full h-full z-999">
      <div className="relative h-full w-full flex flex-col items-center justify-center">
        <div className="absolute inset-0 bg-body opacity-25" onClick={close} />
        <div className="bg-white p-12 pb-14 border border-dgrey relative text-center rounded">
          <div
            className="w-6 h-6 absolute top-0 right-0 login-grey cursor-pointer opacity-50 mt-4 mr-2"
            onClick={close}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#8691A7"
              />
            </svg>
          </div>
          <h3 className="mt-0 mb-10">Log into Percent</h3>
          <div className="grid md:grid-cols-2 gap-4">
            {items.map(({ icon, heading, content, url }, i) => (
              <a
                key={i}
                href={url}
                className="rounded rounded-sm border border-dgrey pb-4 pt-8 px-8 flex flex-col items-center justify-center"
              >
                <div className="h-14 w-14 rounded">{icon}</div>
                <h4 className="mt-3 login-modal-title font-medium font-sans">
                  {heading}
                </h4>
                <p className="mt-0 login-grey opacity-90">{content}</p>
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginModal;
