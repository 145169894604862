/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { globalHistory, navigate } from '@reach/router';
import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import Footer from '~/components/Global/Footer';
import Header from '~/components/Global/Header';
import LoginModal from '~/components/Global/LoginModal';
import './../main.scss';

interface LayoutProps {
  children: ReactNode;
  bgcolour?: string;
}

const Layout: FunctionComponent<LayoutProps> = ({
  children,
  bgcolour,
}: LayoutProps): ReactElement => {
  const [loginModalOpen, setLoginModalOpen] = useState(false);

  // Listen to route changes, if route change === #login, show modal
  useEffect(() => {
    return globalHistory.listen(({ location: { hash } }) => {
      setLoginModalOpen(hash === '#login');
    });
  }, []);

  // remove the hash on the route on close
  useEffect(() => {
    if (typeof window === 'undefined') return null;
    if (window.location.hash === '#login' && loginModalOpen === false) {
      navigate(window.location.pathname);
    }
  }, [loginModalOpen]);

  return (
    <div className={bgcolour}>
      {loginModalOpen && <LoginModal close={() => setLoginModalOpen(false)} />}
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
