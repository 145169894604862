import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import React, { FunctionComponent, ReactElement, useState } from 'react';
import ReactModal from 'react-modal';
import HubspotForm from '~/components/Global/HubspotForm';
import SEO from '~/components/Global/SEO';
import Layout from '~/components/Layout';
import { ContactPageQuery } from '../graphql-types';

interface ContactProps {
  data: ContactPageQuery;
}
declare global {
  interface Window {
    HubSpotConversations: {
      widget: {
        open;
      };
    };
  }
}

function openHubspot() {
  if (typeof window === 'undefined') return;
  window?.HubSpotConversations?.widget?.open();
}

const Contact: FunctionComponent<ContactProps> = ({ data }): ReactElement => {
  const {
    contactMasthead,
    salesAndSupport,
    officesSectionHeading,
    officesSectionImage,
    offices,
  } = data.wpPage.contactPage;

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Layout bgcolour="bg-lgrey">
      <SEO title="Contact" />

      <section className="py-12 bg-lgrey md:py-24 md:pt-20">
        <div className="container text-center">
          <h1>{contactMasthead?.heading}</h1>
          <p className="max-w-md mx-auto">{contactMasthead?.content}</p>
          <div className="mx-auto mt-12 md:flex md:mt-16 lg:max-w-3xl">
            <div className="flex-1 p-8 mb-10 text-center bg-white border md:p-12 md:mx-4 md:text-left shadow-solid border-dgrey md:-mb-56">
              <h4>{salesAndSupport.salesHeading}</h4>
              <p
                className="mx-auto text-xs md:mx-0"
                dangerouslySetInnerHTML={{
                  __html: salesAndSupport.salesDescription,
                }}
              />
              <ul className="mt-4 mb-6">
                {salesAndSupport?.salesTelephone?.length > 0 && (
                  <li>
                    <a
                      href={`tel:${salesAndSupport.salesTelephone}`}
                      className="inline-flex items-center justify-center text-sm text-link md:justify-start"
                    >
                      <span>{salesAndSupport.salesTelephone}</span>
                    </a>
                  </li>
                )}
                {salesAndSupport?.salesEmail?.length > 0 && (
                  <li>
                    <a
                      href={`mailto:${salesAndSupport.salesEmail}`}
                      className="inline-flex items-center justify-center text-sm text-link md:justify-start"
                    >
                      <span>{salesAndSupport.salesEmail}</span>
                    </a>
                  </li>
                )}
              </ul>

              <a
                href="https://poweredbypercent.zendesk.com/hc/en-us/requests/new?ticket_form_id=6528109463441"
                className="btn"
              >
                {salesAndSupport.salesButton.title}
              </a>

              <ReactModal
                isOpen={modalOpen}
                style={{
                  overlay: { zIndex: '1000' },
                  content: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    border: 'none',
                    background: 'transparent',
                    overflow: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '0',
                  },
                }}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                ariaHideApp={false}
                preventScroll={false}
                onRequestClose={() => setModalOpen(false)}
              >
                <div className="flex flex-col items-center justify-center min-h-screen p-8 text-center pointer-events-none">
                  <div
                    className="relative inline-block p-12 text-left bg-white rounded pointer-events-auto"
                    style={{ border: '1px solid #eee' }}
                  >
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-0 right-0 cursor-pointer"
                      onClick={() => setModalOpen(false)}
                    >
                      <circle cx="30" cy="30" r="30" fill="white" />
                      <line
                        x1="25.7452"
                        y1="25.7458"
                        x2="34.1779"
                        y2="34.1786"
                        stroke="black"
                        strokeWidth="2"
                      />
                      <line
                        x1="34.209"
                        y1="25.7781"
                        x2="25.7763"
                        y2="34.2109"
                        stroke="black"
                        strokeWidth="2"
                      />
                    </svg>

                    <HubspotForm
                      formId={'f4eb5a03-0143-423b-a71f-62067fa4b93a'}
                    />
                  </div>
                </div>
              </ReactModal>
            </div>
            <div className="flex flex-col justify-between flex-1 p-8 text-center bg-white border md:p-12 md:mx-4 md:text-left shadow-solid border-dgrey md:-mb-56">
              <div>
                <h4>{salesAndSupport.supportHeading}</h4>
                <p
                  className="mx-auto mb-4 text-xs md:mx-0"
                  dangerouslySetInnerHTML={{
                    __html: salesAndSupport.supportDescription,
                  }}
                />
              </div>

              {salesAndSupport.supportButton.title.length > 0 && (
                <div>
                  <a
                    href="https://poweredbypercent.zendesk.com/hc/en-us"
                    className="btn"
                  >
                    {salesAndSupport.supportButton.title}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <main>
        <section className="pt-12 my-0 bg-white pb-14 md:pt-64 md:pb-20">
          <div className="container">
            <h2 className="text-center">{officesSectionHeading}</h2>
            <div className="offices-image-container">
              <Image
                fluid={officesSectionImage.localFile.childImageSharp.fluid}
                imgStyle={{ objectFit: 'contain', height: '100%' }}
                className="object-contain h-full my-10"
              />
            </div>
            <div className="grid max-w-4xl gap-12 mx-auto mt-12 sm:grid-cols-3">
              {offices.map(
                ({
                  officeTitle,
                  address,
                  directionsLink,
                  telephone,
                  email,
                }) => (
                  <div key={officeTitle} className="text-center md:text-left">
                    <div>
                      <h3>{officeTitle}</h3>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: address,
                        }}
                      />
                      {directionsLink?.title && (
                        <a
                          href={directionsLink.url}
                          target="_blank"
                          rel="noreferrer noopener"
                          className="text-sm text-link text-navy hover:text-red"
                        >
                          {directionsLink.title}
                        </a>
                      )}
                      <ul>
                        {telephone && (
                          <li className="mt-6">
                            <a
                              href={`tel:${telephone}`}
                              className="inline-flex items-center justify-center text-sm text-link md:justify-start"
                            >
                              <span>{telephone}</span>
                            </a>
                          </li>
                        )}
                        {email && (
                          <li>
                            <a
                              href={`mailto:${email}`}
                              className="inline-flex items-center justify-center text-sm text-link md:justify-start"
                            >
                              <span>{email}</span>
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Contact;

export const query = graphql`
  query ContactPage($id: String!) {
    wpPage(id: { eq: $id }) {
      contactPage {
        contactMasthead {
          heading
          content
        }
        salesAndSupport {
          salesHeading
          salesDescription
          salesTelephone
          salesEmail
          salesButton {
            title
            url
          }
          supportHeading
          supportDescription
          supportButton {
            title
            url
          }
        }
        officesSectionHeading
        officesSectionImage {
          ...Image
        }
        offices {
          officeTitle
          address
          directionsLink {
            title
            url
            target
          }
          telephone
          email
        }
      }
    }
  }
`;
