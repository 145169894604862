import React, { FunctionComponent, ReactElement, useEffect } from 'react';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    hbspt: {
      forms: {
        create: (options: {
          region: string;
          portalId: string;
          formId: string;
          target: string;
          onReady?: () => void;
          onFormSubmit?: () => void;
        }) => void;
      };
    };
  }
}

const HubspotForm: FunctionComponent<{
  formId: string;
  handleSubmit?: () => void;
}> = ({ formId, handleSubmit }): ReactElement => {
  function handler(event) {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmitted' &&
      typeof handleSubmit === 'function'
    ) {
      handleSubmit();
    }
  }

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  useEffect(() => {
    if ('hbspt' in window) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '7220198',
        formId: formId,
        target: `#form-${formId}`,
      });
    }
  }, []);

  return <div id={`form-${formId}`} />;
};

export default HubspotForm;
